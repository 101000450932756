import { useCallback } from "react";
import { IOrganization, ISensor, ITenant } from "src/types";
import {
  useImageUploadActions,
  useImageUploadState,
} from "src/components/useImageUploadStore";
import Grid2 from "@mui/material/Unstable_Grid2";
import TenantsSelect from "src/components/TenantsSelect";
import OrganizationsSelect from "src/components/OrganizationsSelect";
import SensorsSelect from "src/components/SensorsSelect";
import { useUser } from "src/api/useUser";
import { useOrganizations } from "src/api/useOrganizations";
import { useTenants } from "src/api/useTenants";

export default function EnterpriseOrgSensor() {
  const {
    enterprise: tenant,
    organization: org,
    sensorType,
  } = useImageUploadState();
  const userQ = useUser();
  const orgsQ = useOrganizations(tenant?.id);
  const tenantsQ = useTenants();
  const {
    setOverwriteCollectEvent,
    setEnterprise,
    setOrganization,
    setSensorType,
  } = useImageUploadActions();

  const handleOrgChange = useCallback(
    async (_e, opt) => {
      const item = opt as IOrganization;
      setOrganization(item);
      setSensorType(undefined);
    },
    [setOrganization, setSensorType]
  );

  const handleTenantChange = useCallback(
    (_e, opt) => {
      const item = opt as ITenant;
      setEnterprise(item);
      setOrganization(undefined);
      setSensorType(undefined);
      setOverwriteCollectEvent(undefined);
    },
    [setEnterprise, setOrganization, setOverwriteCollectEvent, setSensorType]
  );
  const handleSensorChange = useCallback(
    async (_e, opt) => {
      const item = opt as ISensor;
      setSensorType(item);
      setOverwriteCollectEvent(undefined);
    },
    [setOverwriteCollectEvent, setSensorType]
  );

  return (
    <Grid2 container spacing={2}>
      <Grid2 sm={12} md={4}>
        <TenantsSelect
          onChange={handleTenantChange}
          value={
            (!userQ.data?.isGlobalAdmin ? tenantsQ.data?.[0] : tenant) ?? null
          }
        />
      </Grid2>
      <Grid2 sm={12} md={4}>
        <OrganizationsSelect
          tenantId={tenant?.id}
          onChange={handleOrgChange}
          value={(userQ.data?.isOrgUser ? orgsQ.data?.[0] : org) ?? null}
        />
      </Grid2>
      <Grid2 sm={12} md={4}>
        <SensorsSelect
          org={org?.slug}
          onChange={handleSensorChange}
          value={sensorType ?? null}
        />
      </Grid2>
    </Grid2>
  );
}
