import { useCallback } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { ICollectEvent } from "src/types/ICollectEvent";

export function useCollectEvents(orgSlug?: string, sensorTypeId?: string) {
  const getData = useCallback(async () => {
    const res = await axios.get<ICollectEvent[]>(
      `/${orgSlug}/collectevents?sensorTypeId=${sensorTypeId}`
    );
    return res?.data?.map((item) => {
      const beginDate = new Date(item.beginOnUtc).toLocaleDateString();
      const endDate = new Date(item.endOnUtc).toLocaleDateString();
      return {
        // value: item.id,
        ...item,
        label: `${beginDate} - ${endDate}${item.name ? ` (${item.name})` : ""}`,
      };
    });
  }, [orgSlug, sensorTypeId]);

  return useQuery({
    queryKey: ["organization", orgSlug, "collectevents", sensorTypeId],
    queryFn: getData,
    enabled: !!orgSlug && !!sensorTypeId,
  });
}
