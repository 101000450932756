import { useEffect } from "react";
import axios, { AxiosRequestHeaders } from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const langPref = localStorage.getItem("cai:lang:preference");
const finalHeaders = {} as AxiosRequestHeaders;
// set language if it has been set in localStorage
if (langPref) {
  // check languages set on browser, and pull any that match langPref to include subtags
  // eg: langPref === 'en' and user has 'en-CA' before/instead of 'en', this would use the "en-CA" value
  const langs = window.navigator.languages ?? [window.navigator.language];
  const lang = langs.find((l) => l.includes(langPref)) || langPref;
  finalHeaders["accept-language"] = lang;
}
export default function AxiosInterceptor(): null {
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    axios.defaults.headers.common = finalHeaders;
    axios.defaults.baseURL = process.env.REACT_APP_API_ORIGIN;
    axios.interceptors.request.use(
      async function (config) {
        const token = await getAccessTokenSilently();
        config.headers["Authorization"] = `Bearer ${token}`;
        return config;
      }
      // function (error) {
      //   // Do something with request error
      //   return Promise.reject(error);
      // }
    );
    axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(
          error?.response?.data?.detail ?? error?.response?.data?.title ?? error
        );
      }
    );
  }, [getAccessTokenSilently]);
  return null;
}
