import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "src/lib/Select/Autocomplete";
import Grid from "@mui/material/Unstable_Grid2";
import { ThemeSwitch } from "src/lib/theme";
import { useSettingsStore } from "src/components/useSettingsStore";

export function SettingsView() {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const { actions, theme, language } = useSettingsStore();

  const languages = useMemo(
    () => [
      { label: t("settings.english"), value: "en" },
      { label: t("settings.spanish"), value: "es" },
      { label: t("settings.french"), value: "fr" },
    ],
    [t]
  );

  return (
    <Stack spacing={2}>
      <Typography textTransform={"uppercase"} variant="h5" component="h2">
        {t("settings.title")}
      </Typography>
      <Grid container spacing={2}>
        {/* <Grid sm={12}>
          <Stack spacing={2} component={Paper} sx={{ p: 2 }}>
            <Typography textTransform={"uppercase"} variant="h6" component="h3">
              {t("settings.profile")}
            </Typography>
          </Stack>
        </Grid> */}
        <Grid sm={12}>
          <Stack spacing={2} component={Paper} sx={{ p: 2 }}>
            <Typography textTransform={"uppercase"} variant="h6" component="h3">
              {t("settings.preferences")}
            </Typography>
            <Autocomplete
              label={t("settings.language")}
              options={languages}
              value={
                languages.find((l) => l.value === language) ?? languages[0]
              }
              onChange={(_e, item) => {
                const lang = item as { value: string };
                actions.setLanguage(lang?.value);
                i18n.changeLanguage(lang?.value);
              }}
            />
            <ThemeSwitch
              checked={theme === "dark"}
              onChange={(e) => {
                actions.setTheme(e.target.checked ? "dark" : "light");
              }}
            />
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}
