import { useCallback } from "react";
import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ICollectEvent } from "src/types/ICollectEvent";

export function usePostCollectEvent(org?: string) {
  const queryClient = useQueryClient();
  const postToCollectEvent = useCallback(
    async (body: Omit<ICollectEvent, "id">): Promise<ICollectEvent> => {
      if (!org) {
        throw Error("No organization provided.");
      }
      const res = await axios.post(`/${org}/collectevents`, body);
      return res.data;
    },
    [org]
  );
  return useMutation<ICollectEvent, string, Omit<ICollectEvent, "id">, unknown>(
    {
      mutationFn: postToCollectEvent,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["organization", org],
        });
      },
    }
  );
}
