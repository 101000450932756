import { Theme } from "@mui/material/styles";
import Link from "@mui/material/Link";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";

function linkThemeProps(theme: Theme) {
  return {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
    color:
      theme.palette.mode === "dark"
        ? theme.palette.warning.main
        : theme.palette.info.main,
  };
}

export default function PrivacyAndTOS({ linksOnly }: { linksOnly?: boolean }) {
  const { t } = useTranslation();
  return (
    <Typography>
      {!linksOnly ? `${t("auth.bySigningInYouAgreeToOur")} ` : null}
      <Link
        href="https://platform.cultivateagi.com/legal/terms-of-service.html"
        sx={linkThemeProps}
      >
        {t("auth.termsOfService")}
      </Link>
      {` and `}{" "}
      <Link
        sx={linkThemeProps}
        href="https://platform.cultivateagi.com/legal/privacy-policy.html"
      >
        {t("auth.privacyPolicy")}
      </Link>
      {!linksOnly ? `.` : null}
    </Typography>
  );
}
