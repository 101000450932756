import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { AuthView } from "./AuthView";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

export function LoginView() {
  const { error, user, isLoading, logout, loginWithRedirect } = useAuth0();
  const { t } = useTranslation();

  // we have a user, go to ortho by default
  return user && !isLoading ? (
    <Navigate to="/ortho" />
  ) : isLoading ? (
    <AuthView>{t("auth.signingIn")}...</AuthView>
  ) : (
    <>
      {/* not trying to authenticate and no error, show sign in */}
      {!isLoading && !error ? (
        <AuthView>
          <Button
            sx={{ minWidth: "150px" }}
            variant="outlined"
            color="inherit"
            onClick={() => {
              loginWithRedirect();
            }}
          >
            {t("auth.signIn")}
          </Button>
        </AuthView>
      ) : error ? ( // show error with logout button if error
        <AuthView>
          <Stack
            spacing={2}
            sx={{
              m: 24,
              alignItems: "center",
            }}
          >
            <Alert severity="error">
              {error?.message ??
                "Something went wrong trying to sign the user in."}
            </Alert>
            <Button
              color="inherit"
              variant="outlined"
              onClick={() => {
                logout({ logoutParams: { returnTo: window.location.origin } });
              }}
            >
              {t("common.tryAgain")}
            </Button>
          </Stack>
        </AuthView>
      ) : null}
    </>
  );
}
