import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { AuthView } from "src/views/AuthView";
import { LinearProgress } from "@mui/material";

function LoadingView() {
  const { t } = useTranslation();
  return (
    <>
      <LinearProgress sx={{ position: "absolute", left: 0, right: 0 }} />
      <AuthView>{`${t("auth.signingIn")}...`}</AuthView>
    </>
  );
}

function WithAuthWrapper({ children }: { children: React.ReactNode }) {
  return <>{children}</>;
}

const WithAuth = withAuthenticationRequired(WithAuthWrapper, {
  onRedirecting: () => <LoadingView />,
});

export const RequireAuth = ({ children }: { children: React.ReactNode }) => {
  return <WithAuth>{children}</WithAuth>;
};
