import { create } from "zustand";

interface ImageUploadStore {
  language: string;
  theme: "dark" | "light";
  actions: {
    setTheme: (_t: "dark" | "light") => void;
    setLanguage: (_l: string) => void;
  };
}
const mode = window.localStorage?.getItem("cai:theme:preference") as
  | "dark"
  | "light";

const lang = window.localStorage?.getItem("cai:lang:preference");

export const useSettingsStore = create<ImageUploadStore>((set) => ({
  language: lang ?? "en",
  theme: mode ?? "dark",
  actions: {
    setLanguage: (language) => {
      window.localStorage.setItem("cai:lang:preference", language);
      return set(() => ({
        language,
      }));
    },
    setTheme: (theme) => {
      window.localStorage.setItem("cai:theme:preference", theme);
      document.body.className = theme;
      return set(() => ({
        theme,
      }));
    },
  },
}));
