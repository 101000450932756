import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import useSidebarState from "./useSidebarState";
import { useTheme } from "@mui/material/styles";
import { SxProps } from "@mui/material";
import ButtonNoRipples from "../ButtonNoRipples";

export default function SidebarListItem({
  component,
  href,
  icon,
  onClick,
  selected,
  target,
  text,
  secondaryAction,
  sx,
}: {
  component?: string | React.ReactElement;
  href?: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  selected?: boolean;
  target?: React.HTMLAttributeAnchorTarget;
  text: React.ReactNode;
  secondaryAction?: React.ReactNode;
  sx?: SxProps;
}) {
  const { open } = useSidebarState();
  const theme = useTheme();
  return (
    <ListItem
      disablePadding
      sx={{
        display: "block",
        "a:hover": {
          color: theme.palette.mode === "light" ? "#121212" : "#fff",
          fontWeight: "bold",
        },
        "&.MuiListItem-root .Mui-selected": {
          backgroundColor: theme.palette.mode === "light" ? "#fff" : "#121212",
          "&:hover": {
            backgroundColor:
              theme.palette.mode === "light" ? "#fff" : "#121212",
          },
        },
        ...sx,
      }}
      secondaryAction={secondaryAction}
    >
      <ListItemButton
        disableTouchRipple={true}
        selected={selected}
        // component={component}
        {...(href
          ? {
              component: component ?? ButtonNoRipples,
              href,
              target,
            }
          : {
              component,
            })}
        onClick={onClick}
      >
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  );
}
