import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const blobToImage = (blob: Blob) => {
  return new Promise<HTMLImageElement>((resolve) => {
    const reader = new window.FileReader();
    reader.readAsDataURL(blob);
    reader.onload = (e) => {
      const img = new window.Image();
      img.onload = () => {
        resolve(img as HTMLImageElement);
      };
      img.src = e.target?.result as string;
    };
  });
};
const portalApiOrigin = process.env.REACT_APP_PORTAL_API_ORIGIN;

const logoHeaders = {
  transformResponse: async (res: Blob) => {
    const x = await blobToImage(res);
    return x;
  },
};

export function useCAILogo(theme: "dark" | "light") {
  const fetchCAILogos = useCallback(async () => {
    const res = await axios.get(
      `${portalApiOrigin}/enterprises/cai/config/logos/${theme || "dark"}`,
      {
        ...logoHeaders,
        responseType: "blob",
      }
    );
    return res.data;
  }, [theme]);

  return useQuery({
    queryKey: ["cai-logos", theme],
    queryFn: fetchCAILogos,
    staleTime: Infinity,
  });
}
