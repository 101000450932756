import { IOrganization, ISensor, ITenant } from "src/types";
import { ICollectEvent } from "src/types/ICollectEvent";
import { create } from "zustand";

interface ImageUploadData {
  tenantIdOrSlug?: string;
  organizationSlug?: string;
  sensorTypeId?: string;
  collectEventName?: string;
  beginOnUtc?: string;
  endOnUtc?: string;
}

interface ImageUploadStore {
  formData?: ImageUploadData;
  enterprise?: ITenant;
  organization?: IOrganization;
  sensorType?: ISensor;
  startDate?: Date;
  endDate?: Date;
  overwriteCollectEvent?: ICollectEvent;
  actions: {
    setCollectEventName: (_n: string) => void;
    setOverwriteCollectEvent: (_c?: ICollectEvent) => void;
    setEnterprise: (_d?: ITenant) => void;
    setOrganization: (_d?: IOrganization) => void;
    setSensorType: (_d?: ISensor) => void;
    setStartDate: (_d?: Date) => void;
    setEndDate: (_d?: Date) => void;
  };
}

const defaultDate = new Date();

export const useImageUploadStore = create<ImageUploadStore>((set) => ({
  formData: {
    collectEventName: "",
    beginOnUtc: defaultDate.toISOString(),
    endOnUtc: defaultDate.toISOString(),
  },
  startDate: defaultDate,
  endDate: defaultDate,
  actions: {
    setStartDate: (startDate) => {
      return set(({ formData }) => ({
        startDate,
        formData: {
          ...formData,
          beginOnUtc: startDate?.toISOString(),
        },
      }));
    },
    setEndDate: (endDate) => {
      return set(({ formData }) => ({
        endDate,
        formData: { ...formData, endOnUtc: endDate?.toISOString() },
      }));
    },
    setCollectEventName: (collectEventName) => {
      return set(({ formData }) => ({
        formData: { ...formData, collectEventName },
      }));
    },
    setEnterprise: (enterprise) => {
      return set(({ formData }) => ({
        enterprise,
        formData: { ...formData, tenantIdOrSlug: enterprise?.id },
      }));
    },
    setOrganization: (organization) => {
      return set(({ formData }) => ({
        organization,
        formData: { ...formData, organizationSlug: organization?.slug },
      }));
    },
    setOverwriteCollectEvent: (overwriteCollectEvent) => {
      return set(() => ({
        overwriteCollectEvent,
      }));
    },
    setSensorType: (sensorType) => {
      return set(({ formData }) => ({
        sensorType,
        formData: { ...formData, sensorTypeId: sensorType?.id },
      }));
    },
  },
}));

export const useImageUploadActions = () =>
  useImageUploadStore((state) => state.actions);

export const useImageUploadState = () =>
  useImageUploadStore((state) => ({ ...state }));

export const useImageUploadFormData = () =>
  useImageUploadStore((state) => state.formData);
