import { useTranslation } from "react-i18next";
import { useSensors } from "src/api/useSensors";
import Autocomplete, {
  CustomAutocompleteProps,
} from "src/lib/Select/Autocomplete";
import { ISensor } from "src/types";

export default function SensorsSelect({
  org,
  ...props
}: Omit<CustomAutocompleteProps<ISensor>, "value"> & {
  org?: string;
  value?: ISensor | null;
}) {
  const { t } = useTranslation();
  const sensorsQ = useSensors(org);
  return (
    <Autocomplete
      loading={sensorsQ.isLoading}
      label={t("common.sensor")}
      options={sensorsQ.data ?? []}
      getOptionLabel={(o) => (typeof o !== "string" ? o.name ?? "" : o)}
      {...props}
    />
  );
}
