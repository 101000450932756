import { useCallback } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useUser } from "./useUser";
import { ITenant } from "src/types";
import { useImageUploadActions } from "src/components/useImageUploadStore";

export function useTenants() {
  const { data: userQ } = useUser();
  const actions = useImageUploadActions();

  const getData = useCallback(async () => {
    if (userQ?.isGlobalAdmin) {
      const res = await axios.get<ITenant[]>(`/portal/tenants`);
      return res.data;
    } else {
      const opt = {
        ...userQ?.metadata.tenant,
      } as ITenant;
      actions.setEnterprise(opt);
      return [opt];
    }
  }, [actions, userQ]);

  return useQuery({
    queryKey: ["tenants", userQ],
    queryFn: getData,
    staleTime: 10 * (60 * 1000), // 10 mins,
    enabled: !!userQ,
  });
}
