import { useTranslation } from "react-i18next";
import { useTenants } from "src/api/useTenants";
import Autocomplete, {
  CustomAutocompleteProps,
} from "src/lib/Select/Autocomplete";
import { ITenant } from "src/types";

export default function TenantsSelect(
  props: Omit<CustomAutocompleteProps<ITenant>, "value"> & {
    value?: ITenant | null;
  }
) {
  const { t } = useTranslation();
  const tenantsQ = useTenants();
  return (
    <Autocomplete
      loading={tenantsQ.isLoading}
      label={t("common.enterprise")}
      options={tenantsQ.data ?? []}
      getOptionLabel={(o) => (typeof o !== "string" ? o?.name ?? "" : o)}
      {...props}
    />
  );
}
