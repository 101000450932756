import React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import AutocompleteMUI, { AutocompleteProps } from "@mui/material/Autocomplete";
import { InputProps } from "@mui/material/Input";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";

export interface CustomAutocompleteProps<T = unknown>
  extends Partial<AutocompleteProps<T, boolean, boolean, boolean>> {
  error?: boolean;
  name?: string;
  label?: string;
  size?: "small" | "medium";
  InputProps?: InputProps;
  TextFieldProps?: TextFieldProps;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}

type ConditionalProps<T = unknown> =
  | {
      value?: T | null;
    }
  | {
      value?: T[] | null;
    };

const DEFAULT_TEXT_FIELD_PROPS = {
  size: "small",
} as Partial<TextFieldProps>;
export default function Autocomplete<T = unknown>(
  props: CustomAutocompleteProps<T> & ConditionalProps<T>
) {
  const {
    error,
    inputProps,
    TextFieldProps,
    InputProps,
    name,
    sx,
    multiple,
    ...rest
  } = props;
  const { t } = useTranslation();
  return (
    <Box sx={{ position: "relative" }}>
      <AutocompleteMUI
        disableClearable
        multiple={multiple}
        loadingText={`${t("common.loadingData")}...`}
        ChipProps={{ size: "small" }}
        sx={{
          "*": {
            cursor: "pointer",
          },
          width: "100%",
          ...sx,
        }}
        renderInput={(params) => (
          <TextField
            error={error}
            {...params}
            {...DEFAULT_TEXT_FIELD_PROPS}
            {...TextFieldProps}
            {...(!name && inputProps
              ? {
                  inputProps: { ...inputProps, ...params.inputProps },
                }
              : {})}
            InputProps={{
              sx: {
                backgroundColor: (theme) =>
                  theme.palette.mode === "light" ? "#fff" : "#121212",
              },
              ...params.InputProps,
              ...InputProps,
              ...(rest.loading
                ? {
                    endAdornment: <CircularProgress size={"1rem"} />,
                  }
                : {
                    endAdornment:
                      InputProps?.endAdornment ??
                      params.InputProps?.endAdornment,
                  }),
              ...(InputProps?.startAdornment
                ? {
                    startAdornment: (
                      <>
                        {InputProps?.startAdornment}
                        {params.InputProps?.startAdornment}
                      </>
                    ),
                  }
                : {}),
            }}
            label={props?.label?.toUpperCase()}
            placeholder={props?.placeholder}
          />
        )}
        {...rest}
        options={props?.options ?? []}
      />
      {name ? (
        /* NOTE: hidden input to get around shenanigans 
        getting labels/values/UI to play together. Only used
        if name is provided on inputProps */
        <input
          style={{
            height: "1px",
            margin: 0,
            padding: 0,
            border: "none",
            position: "absolute",
            opacity: 0,
            width: "100%",
          }}
          name={name}
          {...inputProps}
          value={inputProps?.value ?? ""}
          // noop if not provided to shush value without onchange warning
          onChange={inputProps?.onChange ? inputProps?.onChange : () => {}}
        />
      ) : null}
    </Box>
  );
}
