import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import getUserMeta from "./getUserMeta";

export function useUser() {
  const { user } = useAuth0();

  const getData = useCallback(async () => {
    if (user) {
      return getUserMeta(user);
    }
  }, [user]);

  return useQuery({
    queryKey: ["user", user],
    queryFn: getData,
    staleTime: 10 * (60 * 1000), // 10 mins,
    enabled: !!user,
  });
}
