import { useCallback } from "react";
import axios from "axios";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { IOrganization, ITenant } from "src/types";
import { TileCacheResponse } from "src/types/TileCacheResponse";

interface PostParams {
  id: string;
  collectEventId: string;
  enterprise: ITenant;
  organization: IOrganization;
  file: File;
  method: "post" | "put";
}

export function useOrthoUploader(
  opts: UseMutationOptions<TileCacheResponse, string, PostParams, unknown>
) {
  const postToTiler = useCallback(
    async ({
      collectEventId,
      enterprise,
      organization,
      file,
      method,
    }: PostParams): Promise<{
      collectEventId: string;
      executionTimeSecs: number;
      filename: string;
      name: string;
      totalSizeMb: number;
    }> => {
      const formdata = new window.FormData();
      formdata.append("file", file);
      formdata.append("tenant", enterprise.slug ?? enterprise.id ?? "");
      formdata.append("org", organization.slug ?? "");
      formdata.append("collectEventId", collectEventId);
      const res = await axios[method](
        process.env.REACT_APP_RASTER_TILER_API_ORIGIN ?? "",
        formdata,
        {
          baseURL: "",
        }
      );
      return res.data;
    },
    []
  );

  return useMutation({
    mutationFn: postToTiler,
    ...opts,
  });
}
