import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import PrivacyAndTOS from "./PrivacyAndTOS";

export function AuthView({ children }: { children: React.ReactNode }) {
  return (
    <Stack
      spacing={2}
      sx={{
        p: 20,
        alignItems: "center",
      }}
    >
      <Box>
        <Box className={"main-logo"}></Box>
        <Typography textTransform={"uppercase"} variant="h4" component={"h1"}>
          Import Imagery
        </Typography>
      </Box>
      {children}
      <PrivacyAndTOS />
    </Stack>
  );
}
