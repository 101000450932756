import { useCallback } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useUser } from "./useUser";
import { useImageUploadActions } from "src/components/useImageUploadStore";
import { IOrganization } from "src/types";

export function useOrganizations(tenantIdOrSlug?: string) {
  const { data: userQ } = useUser();
  const actions = useImageUploadActions();

  const getData = useCallback(async () => {
    if (userQ?.isOrgUser) {
      const opt = {
        ...userQ.metadata.tenant.org,
        label: userQ.metadata.tenant.org.name,
        value: userQ.metadata.tenant.org.id,
        slug: userQ.metadata.tenant.org.id,
      };
      actions.setOrganization(opt);
      return [opt];
    }
    const res = await axios.get<IOrganization[]>(
      `/portal/tenants/${tenantIdOrSlug}/organizations`
    );
    return res?.data;
  }, [actions, tenantIdOrSlug, userQ]);

  return useQuery({
    queryKey: ["tenants", tenantIdOrSlug, "organizations"],
    queryFn: getData,
    enabled:
      !!userQ && !!((!userQ?.isOrgUser && tenantIdOrSlug) || userQ.isOrgUser),
    staleTime: 10 * (60 * 1000), // 10 mins
  });
}
