import { User } from "@auth0/auth0-react";

type UserRole =
  | "ent:acctmgr"
  | "ent:admin"
  | "ent:orgacctmgr"
  | "ent:user"
  | "global:admin"
  | "org:acctmgr"
  | "org:admin"
  | "org:user";

interface AppMetadata {
  cai_user_id: string;
  role: UserRole;
  tenant: {
    id: string;
    name: string;
    org: {
      id: string;
      name: string;
    };
  };
  //   send_welcome_email: boolean;
}

export default function getUserMeta(user: User) {
  // putting the auth0 claim props under easier to access names
  const metadata = user[
    "https://schemas.cultivateagi.com/app_metadata"
  ] as AppMetadata;
  return {
    ...user,
    metadata,
    isGlobalAdmin: metadata?.role === "global:admin",
    isOrgUser: metadata?.role.startsWith("org:"),
    isEntUser: metadata?.role.startsWith("ent:"),
  };
}
