import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";
import { RequestData } from "src/types/RequestData";

export default function RequestTable({
  requests,
}: {
  requests: RequestData[];
}) {
  const { t } = useTranslation();
  return (
    <TableContainer>
      <Table aria-label="request table">
        <TableHead>
          <TableRow>
            <TableCell>{t("requestTable.started")}</TableCell>
            <TableCell align="right">
              {t("requestTable.collectEventName")}
            </TableCell>
            <TableCell align="right">{t("requestTable.filename")}</TableCell>
            <TableCell align="right">{t("requestTable.status")}</TableCell>
            <TableCell align="right">
              {t("requestTable.totalSize")} (mb)
            </TableCell>
            <TableCell align="right">
              {t("requestTable.totalTime")} (s)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {requests.length ? (
            requests.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  bgcolor:
                    !row.isError && row.response
                      ? "success.main"
                      : row.isError
                      ? "error.main"
                      : "",
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  {row.startedOn.toLocaleString()}
                </TableCell>
                <TableCell align="right">{row.collectEventName}</TableCell>
                <TableCell align="right">{row.file.name}</TableCell>
                <TableCell align="right">{row.statusMessage}</TableCell>
                <TableCell align="right">{row.response?.totalSizeMb}</TableCell>
                <TableCell align="right">
                  {row.response?.executionTimeSecs}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                sx={{ textAlign: "center" }}
                colSpan={6}
                component="th"
                scope="row"
              >
                {t("requestTable.noData")}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
