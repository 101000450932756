export function downloadFile({
  data,
  filename,
  filetype,
}: {
  data: unknown;
  filename: string;
  filetype: string;
}): void {
  let blob;
  switch (filetype) {
    case "text/plain": {
      blob = new Blob([JSON.stringify(data)], {
        type: "text/plain",
      });
      break;
    }
    default: {
      console.error("Invalid file type provided to download.");
    }
  }

  if (blob) {
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(link.href);
    link.remove();
  }
}
