import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { useCAILogo } from "src/api/useCAILogo";
import AppLayout from "src/components/AppLayout";
import { SidebarListItem } from "src/lib/Sidebar";
import DashboardIcon from "src/lib/Icons/DashboardIcon";
import AuthNavItem from "src/components/AuthNavItem";
import { Route, Routes, useLocation } from "react-router-dom";
import { OrthoUploader } from "./OrthoUploader";
import { SettingsView } from "./SettingsView";
import { useSettingsStore } from "src/components/useSettingsStore";
import { useTranslation } from "react-i18next";
import { Settings } from "react-feather";

export function App() {
  const location = useLocation();
  const { theme } = useSettingsStore();
  const logoQ = useCAILogo(theme);
  const { t } = useTranslation();
  return (
    <AppLayout
      sidebarChildren={
        <>
          <SidebarListItem
            selected={location.pathname === "/ortho"}
            href={"/ortho"}
            icon={<DashboardIcon />}
            text={"Ortho Uploader"}
          />
          <SidebarListItem
            selected={location.pathname === "/settings"}
            icon={<Settings />}
            text={t("settings.title")}
            sx={{ mt: "auto" }}
            href="/settings"
          />
        </>
      }
      title={
        <>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: 175, display: "flex", alignItems: "center" }}>
              {logoQ.data ? (
                <img style={{ width: "100%" }} src={logoQ.data?.src} />
              ) : logoQ.isLoading ? (
                <Skeleton />
              ) : null}
            </Box>
            <Link sx={{ ml: 2 }} color="inherit" underline="none" href="/">
              <Typography variant="h5" component={"h1"}>
                Import Imagery
              </Typography>
            </Link>
            <Box sx={{ display: "flex", alignItems: "center", ml: "auto" }}>
              <Container maxWidth="xl" sx={{ pr: "0 !important" }}>
                <Toolbar disableGutters>
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <AuthNavItem />
                  </Stack>
                </Toolbar>
              </Container>
            </Box>
          </Box>
        </>
      }
    >
      <Routes>
        <Route path="ortho" element={<OrthoUploader />} />
        <Route path="settings" element={<SettingsView />} />
        <Route
          path="*"
          element={
            <Stack
              spacing={2}
              sx={{
                p: 24,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h3" component="h2">
                {t("common.notFound")}
              </Typography>
              <Button variant="outlined" color="inherit" href="/">
                {t("common.home")}
              </Button>
            </Stack>
          }
        />
      </Routes>
    </AppLayout>
  );
}
