import { useTranslation } from "react-i18next";
import { useOrganizations } from "src/api/useOrganizations";
import { useUser } from "src/api/useUser";
import Autocomplete, {
  CustomAutocompleteProps,
} from "src/lib/Select/Autocomplete";
import { IOrganization } from "src/types";

export default function OrganizationsSelect({
  tenantId,
  value,
  ...props
}: Omit<CustomAutocompleteProps<IOrganization>, "value"> & {
  tenantId?: string;
  value?: IOrganization | null;
}) {
  const { t } = useTranslation();
  const userQ = useUser();
  const orgsQ = useOrganizations(
    userQ.data?.isGlobalAdmin
      ? tenantId ?? ""
      : userQ.data?.metadata.tenant.id ?? ""
  );
  return (
    <Autocomplete
      loading={orgsQ.isLoading}
      label={t("common.organization")}
      options={orgsQ.data ?? []}
      value={value ?? null}
      getOptionLabel={(o) => (typeof o !== "string" ? o.name ?? "" : o)}
      {...props}
    />
  );
}
